/* ready */
$(function () {
  'use strict';

  // modal
  $('[data-area]').on('click', function () {
    var area = $(this).data('area');

    $(`[data-modal="${area}"]`).fadeIn();
    $('body').addClass('is-fixed');
    return false;
  });

  // close
  $('.c-modal button').on('click', function () {
    $(this).closest('.c-modal').fadeOut();
    $('body').removeClass('is-fixed');
  });

  // slide
  $('.p-gnss__kv__slide').slick({
    arrows: false,
    autoplay: true,
    draggable: false,
    fade: true,
    pauseOnHover: false,
    speed: 1000,
    swipe: false
  });
});