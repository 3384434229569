/* ready */
$(function () {
  'use strict';

  $('.p-case__rel__slide').slick({
    speed: 1000,
    slidesToShow: 3,
    dots: true,
    appendArrows: $('.p-case__rel__navi__arrow'),
    appendDots: $('.p-case__rel__navi__dots'),
    draggable: false,
    swipe: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
});