/* ready */
$(function () {
  'use strict';

  // l-gnavi
  $('.l-header--top .l-header__btn').on('click', function () {
    var scroll = $(window).scrollTop(); // クリック時にスクロール位置を取得
    var changeOffset = $('.p-top-mv').height() - $('.l-header').height();
    var logo = $('.l-header--top .l-header__logo a img');

    if (scroll < changeOffset) {
      $('.l-header--top').toggleClass('is-change');
      var logoSrc = logo.attr('src'); // 最新の src を取得
      if (!logoSrc.includes('logo02')) {
        logo.attr('src', logoSrc.replace(/(\.\w+)$/, '02$1'));
      } else {
        var logoSrc = logo.attr('src'); // 最新の src を取得
        logo.attr('src', logoSrc.replace(/02(\.\w+)$/, '$1'));
      }
    }
  });

  // slider
  $('.p-top-mv__slide').slick({
    autoplay: true,
    fade: true,
    arrows: false,
    speed: 1000,
    autoplaySpeed: 4000,
    draggable: false,
    swipe: false
  });

  $('.p-top-pick__slide').slick({
    speed: 1000,
    slidesToShow: 4,
    dots: true,
    appendArrows: $('.p-top-pick__navi__arrow'),
    appendDots: $('.p-top-pick__navi__dots'),
    draggable: false,
    swipe: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  $('.p-top-topics__slide').slick({
    speed: 1000,
    slidesToShow: 4,
    dots: true,
    appendArrows: $('.p-top-topics__navi__arrow'),
    appendDots: $('.p-top-topics__navi__dots'),
    draggable: false,
    swipe: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  animation($('.p-top-mv, .p-top-pick, .p-top-solution, .p-top-case, .p-top-about, .p-top-news, .p-top-topics, .p-top-recruit'));
});

/* scroll */
$(window).on('scroll', function () {
  'use strict';

  let scroll = $(window).scrollTop();
  let changeOffset = $('.p-top-mv').height() - $('.l-header').height();
  let logo = $('.l-header--top .l-header__logo a img');

  if (scroll > changeOffset) {
    $('.l-header--top').addClass('is-change');
    if (logo.length > 0) {
      if (!$('.l-header--top').hasClass('is-hover')) {
        var logoSrc = logo.attr('src'); // ここで最新の src を取得
        if (!logoSrc.includes('logo02')) {
          logo.attr('src', logoSrc.replace(/(\.\w+)$/, '02$1'));
        }
      }
    }
  } else {
    if (!$('.l-header__btn').hasClass('is-open')) {
      $('.l-header--top').removeClass('is-change');
      if (logo.length > 0) {
        if (!$('.l-header--top').hasClass('is-hover')) {
          var logoSrc = logo.attr('src'); // ここでも最新の src を取得
          logo.attr('src', logoSrc.replace(/02(\.\w+)$/, '$1'));
        }
      }
    }
  }

  animation($('.p-top-mv, .p-top-pick, .p-top-solution, .p-top-case, .p-top-about, .p-top-news, .p-top-topics, .p-top-recruit'));
});


// animation
function animation(elm) {
  elm.each(function () {
    var basePosition = $(this).offset().top;
    var scrollPosition = $(window).scrollTop();
    var deviceHeight = $(window).height();

    if (scrollPosition > basePosition - deviceHeight * .7) {
      $(this).addClass('is-animate');
    }
  });
}