/* ready */
$(function () {
  'use strict';

  function checkRequiredFields() {
    let isValid = true;

    // .c-form__ttl--required の直後の input, select, textarea をチェック
    $(".c-form__ttl--required").each(function () {
      let input = $(this).closest('.c-form__item').find("input, select, textarea");

      if (input.length && !input.val().trim()) {
        isValid = false; // 空のフィールドがある場合は無効
      }
    });

    // チェックボックス (name="admin[data][]") の必須チェック
    if ($('input[type="checkbox"][name="admin[data][]"]:checked').length === 0) {
      isValid = false; // 1つもチェックされていない場合は無効
    }

    // 送信ボタンを有効/無効にする
    $('button[name="submitConfirm"]').prop("disabled", !isValid);
  }

  // フォーム入力時にチェック
  $(".c-form__ttl--required").closest('.c-form__item').find("input, select, textarea").on("input change", checkRequiredFields);
  $('input[type="checkbox"][name="admin[data][]"]').on("change", checkRequiredFields);

  // 初回チェック（ページ読み込み時）
  checkRequiredFields();
});