/* ready */
$(function () {
  'use strict';

  if (window.innerWidth < 768) {
    $('.p-column__rel__slide').slick({
      speed: 1000,
      slidesToShow: 2,
      dots: true,
      appendArrows: $('.p-column__rel__navi__arrow'),
      appendDots: $('.p-column__rel__navi__dots'),
      draggable: false,
      swipe: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }
});