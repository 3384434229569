/* ready */
$(function () {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]:not([data-area])').on('click', function () {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });

  // l-gnavi
  $('.l-header__btn').on('click', function () {
    $(this).toggleClass('is-open');
    $('.l-gnavi').stop().slideToggle();
    if ($(this).hasClass('is-open')) {
      $('body').addClass('is-fixed');
    } else {
      $('body').removeClass('is-fixed');
    }
  });

  // l-gnavi:hover
  var logo = $('.l-header__logo a img');

  $('.l-gnavi__list > li').on({
    mouseenter: function () {
      if (window.innerWidth > 1024) {
        if ($(this).find('.l-mnavi').length > 0) {
          $('.l-header').addClass('is-hover');
          if (!$('.l-header').hasClass('l-header--top') || $('.l-header').hasClass('is-change')) {
            var logoSrc = logo.attr('src'); // ここで最新の src を取得
            if (logoSrc.includes('logo02')) {
              logo.attr('src', logoSrc.replace(/02(\.\w+)$/, '$1'));
            }
          }
        }
      }
    },
    mouseleave: function () {
      if (window.innerWidth > 1024) {
        if ($(this).find('.l-mnavi').length > 0) {
          if (window.innerWidth > 1024) {
            $('.l-header').removeClass('is-hover');
          }
          if (!$('.l-header').hasClass('l-header--top') || $('.l-header').hasClass('is-change')) {
            var logoSrc = logo.attr('src'); // ここでも最新の src を取得
            if (!logoSrc.includes('logo02')) {
              logo.attr('src', logoSrc.replace(/(\.\w+)$/, '02$1'));
            }
          }
        }
      }
    }
  });

  // l-mnavi
  $('.l-gnavi__list > li > button').on('click', function () {
    $(this).toggleClass('is-open');
    $(this).next().stop().slideToggle();
  });

  // l-footer__navi
  $('.l-footer__navi__ttl button').on('click', function () {
    $(this).toggleClass('is-open');
    $(this).closest('div').find('ul').stop().slideToggle();
  })

  naviCurrent($('.l-lnavi ul li'));
  gnaviCurrent($('.l-gnavi ul li'));
});

/* scroll */
$(window).on('scroll', function () {
  'use strict';

  // pagetop
  var scroll = $(window).scrollTop();
  var pagetop = $('.l-pagetop');

  // 300px 以上スクロールしたら `.is-show` を追加
  if (scroll > 300) {
    pagetop.addClass('is-show');
  } else {
    pagetop.removeClass('is-show');
  }
});

// naviCurrent
function naviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function () {
    let link = $(this).children('a').attr('href');

    var matchLink = new RegExp('^' + link + '$');

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}

// gnaviCurrent
function gnaviCurrent(naviElm) {
  var currentUrl = location.pathname + location.hash;
  naviElm.each(function () {
    var link = $(this).children('a').attr('href');

    if (link == '/') {
      var matchLink = new RegExp('^' + link + '$');
    } else {
      var matchLink = new RegExp('^' + link);
    }

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}